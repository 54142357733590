import Vue from "vue";
import Router from "vue-router";
import store from "./store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "",
      redirect: "/",
      component: () => import("@/views/pages/MainPortal"),
      children: [
        {
          name: "Index",
          path: "/",
          component: () => import("@/views/pages/IndexMain"),
          meta: { notEmptyUser: true }
        },
        {
          name: "Main",
          path: "/main",
          component: () => import("@/views/pages/Index"),
          alias: ["/activate-drm"],
          meta: { notEmptyUser: true }
        },
        {
          name: "License",
          path: "/license",
          component: () => import("@/views/pages/License"),
          meta: { notEmptyUser: true },
          alias: ["policy"]
        },
        {
          name: "Privacy",
          path: "/privacy",
          component: () => import("@/views/pages/PrivacyPolicy"),
          meta: { notEmptyUser: true }
        },
        {
          path: "/movies/:id",
          component: () => import("@/views/pages/Movie_info"),
          meta: { notEmptyUser: true }
        },
        {
          name: "Serials",
          path: "/serials/:id/episode/:episodes",
          props: route => ({
            id: route.params.id,
            episodes: route.params.episodes || 0
          }),
          component: () => import("@/views/pages/Serial_info"),
          meta: { notEmptyUser: true }
        },
        {
          path: "/albums/:id",
          component: () => import("@/views/pages/Album_info"),
          alias: "/books/:id",
          meta: { notEmptyUser: true }
        },

        {
          name: "MovieList",
          path: "/sections/:section/collections/:collection",
          props: true,
          component: () => import("@/views/pages/Movie_list"),
          meta: { notEmptyUser: true }
        },
        {
          name: "Crew",
          path: "/crew",
          props: true,
          meta: { isFlight: true, crewExist: true },
          component: () => import("@/views/pages/Crew")
        },
        {
          name: "Favorites",
          path: "/favorites",
          props: true,
          component: () => import("@/views/pages/Favorites")
        },
        {
          name: "Services",
          path: "/services",
          meta: { isAuth: true, isFlight: true, isService: true },
          props: true,
          component: () => import("@/views/pages/Services")
        },
        {
          name: "Airline",
          path: "/airline",
          props: true,
          component: () => import("@/views/pages/Airline")
        },
        {
          name: "Menu",
          path: "/menu/:alcohol?",
          meta: { isAuth: true, isFlight: true, isFoodMenu: true },
          props: true,
          component: () => import("@/views/pages/Food_menu")
        },
        {
          name: "Help",
          path: "/help",
          props: true,
          component: () => import("@/views/pages/Help")
        },
        {
          name: "Press",
          path: "/press",
          props: true,
          component: () => import("@/views/pages/Press")
        },
        {
          name: "PressNumber",
          path: "/press/:number",
          props: true,
          component: () => import("@/views/pages/PressNumber")
        },
        {
          name: "PressItem",
          path: "/press/:number/:id",
          props: true,
          component: () => import("@/views/pages/PressItem")
        },
        {
          name: "Shop",
          path: "/shop",
          props: true,
          component: () => import("@/views/pages/Shop")
        },
        {
          name: "UpgradeSeat",
          path: "/upgrade-seat",
          meta: {
            isService: true,
            isAuth: true,
            isFlight: true,
            isUpgrade: true
          },
          props: true,
          component: () => import("@/views/pages/UpgradeSeat")
        },
        {
          name: "PayForm",
          path: "/pay-form",
          meta: { isAuth: true, isFlight: true },
          props: true,
          component: () => import("@/views/pages/PayForm")
        },
        {
          name: "Feedback",
          path: "/feedback",
          meta: { isAuth: true, isFlight: true },
          props: true,
          component: () => import("@/views/pages/Feedback")
        }
      ]
    },
    {
      name: "Captive",
      path: "/captive",
      component: () => import("@/views/pages/Captive")
    },
    {
      name: "Preload",
      path: "/preload",
      component: () => import("@/views/pages/Preload")
    },
    {
      name: "Test",
      path: "/test",
      props: true,
      component: () => import("@/views/pages/Test")
    },
    {
      name: "DetailTariff",
      path: "/detail-tariff-json",
      props: true,
      component: () => import("@/views/pages/DetailTariff")
    },
    {
      name: "ClearCache",
      path: "/clear-cache",
      component: () => import("@/views/pages/ClearCache")
    }
  ]
});
router.beforeEach((to, from, next) => {
  if (to.name === "ClearCache") {
    store.dispatch("STATE_CLEAR");
  }
  if (to.name === "Index") {
    next("/main");
  }
  if (to.query.json && to.name === "Menu") {
    store.commit("user/SET_ENTITY", [
      "user",
      {
        class: to.query.class === "business" ? "c" : ""
      }
    ]);
    store.commit("flight/SET_ENTITY", ["isFoodMenu", true], { root: true });
    store.commit("user/SET_ENTITY", ["authenticate", true]);
    store.commit("user/SET_ENTITY", ["skipCaptive", true]);
    store.commit("user/SET_ENTITY", ["userLoading", false]);
    store.commit("settings/SET_ENTITY", ["preload", true]);
    store.commit("flight/SET_ENTITY", ["isFlight", true]);
  } else if (
    to.name !== "Captive" &&
    to.name !== "Test" &&
    to.name !== "DetailTariff" &&
    store.state.user.skipCaptive === "empty"
  ) {
    next("/captive");
  }
  if (to.name === "Preload" && from.name === null) {
    store.state.user.skipCaptive = "empty";
    next("/captive");
  }
  if (
    to.name !== "Preload" &&
    store.state.settings.preload === false &&
    store.state.user.skipCaptive !== "empty"
  ) {
    next("/preload");
  }
  if (
    to.name === "Services" &&
    store.state.settings.preload === false &&
    store.state.user.skipCaptive !== "empty"
  ) {
    next(from.fullPath);
  }
  if (
    to.matched.some(record => record.meta.notEmptyUser) &&
    store.state.user.authenticate === "empty"
  ) {
    store.commit("user/SET_ENTITY", ["showModal", true]);
    next();
  } else if (
    to.matched.some(record => record.meta.isService) &&
    store.state.flight.isService === false
  ) {
    store.dispatch("flight/services", {}, { root: true }).then(() => {
      if (store.state.flight.isService === false) {
        next(from.fullPath);
      } else {
        next();
      }
    });
  } else if (
    to.matched.some(record => record.meta.requiresLogin) &&
    store.state.user.authenticate === false
  ) {
    next(from.fullPath);
  } else if (
    to.matched.some(record => record.meta.isAuth) &&
    store.state.user.authenticate === false
  ) {
    store.commit("user/SET_ENTITY", ["showModal", true]);
    store.commit("user/SET_ENTITY", ["redirectAfterLogin", to.fullPath], {
      root: true
    });
    next(from.fullPath);
  } else if (
    to.matched.some(record => record.meta.isFlight) &&
    store.state.flight.isFlight === false
  ) {
    next(from.fullPath);
  } else if (
    to.matched.some(record => record.meta.isFoodMenu) &&
    store.state.flight.isFoodMenu === false
  ) {
    next(from.fullPath);
  } else if (
    to.matched.some(record => record.meta.isUpgrade) &&
    store.state.flight.isUpgrade === false
  ) {
    next(from.fullPath);
  } else if (
    to.matched.some(record => record.meta.crewExist) &&
    store.state.crewExist === false
  ) {
    next(from.fullPath);
  } else {
    next();
  }
});
export default router;
