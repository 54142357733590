import Vue from "vue";
import Vuex from "vuex";
import home from "./store/modules/index";
import content from "./store/modules/contents";
import player from "./store/modules/player";
import section from "./store/modules/sections";
import collection from "./store/modules/collection";
import user from "./store/modules/user";
import order from "./store/modules/order";
import flight from "./store/modules/flight";
import audio from "./store/modules/audio";
import favorites from "./store/modules/favorites";
import settings from "./store/modules/settings";
import feedback from "./store/modules/feedback";
import events from "./store/modules/events";
import slider from "./store/modules/slider";
import axios from "axios";
import i18n from "./i18n";
import Cookie from "vue-cookie";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    width: 0,
    height: 0,
    locale: i18n.locale
  },
  mutations: {
    SET_SIZE(state, payload) {
      state.width = payload.width;
      state.height = payload.height;
    },
    SET_ENTITY(state, [key, payload]) {
      state[key] = payload;
    }
  },
  actions: {
    SET_LOCALE({ commit }, payload) {
      i18n.locale = payload;
      axios.defaults.headers.common["Accept-Language"] = payload;
      Cookie.set("locale", payload, 1);
      document.querySelector("html").setAttribute("lang", payload);
      commit("SET_ENTITY", ["locale", payload]);
      commit("content/SET_ENTITY", ["itemLoading", "empty"]);
      commit("home/SET_ENTITY", ["indexLoading", "empty"]);
      commit("section/SET_ENTITY", ["indexLoading", "empty"]);
      commit("collection/SET_ENTITY", ["indexLoading", "empty"]);
      commit("order/SET_ENTITY", ["indexLoading", "empty"]);
      commit("order/SET_ENTITY", ["layoutLoading", "empty"]);
      commit("feedback/SET_ENTITY", ["indexLoading", "empty"]);
      commit("slider/SET_ENTITY", ["indexLoading", "empty"]);
    },
    STATE_CLEAR({ commit }) {
      commit("home/RESET_STATE");
      commit("content/RESET_STATE");
      commit("section/RESET_STATE");
      commit("player/RESET_STATE");
      commit("collection/RESET_STATE");
      commit("user/RESET_STATE");
      commit("order/RESET_STATE");
      commit("flight/RESET_STATE");
      commit("audio/RESET_STATE");
      commit("favorites/RESET_STATE");
      commit("settings/RESET_STATE");
    }
  },
  modules: {
    home,
    content,
    section,
    player,
    collection,
    user,
    order,
    flight,
    audio,
    favorites,
    settings,
    feedback,
    events,
    slider
  }
});
