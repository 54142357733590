const initialState = () => ({
  events: [],
  maxEvents: 5,
  timeout: 1000
});

export const mutations = {
  ADD_EVENT(state, payload) {
    state.events.push(payload);
  },
  CLEAR_EVENT(state) {
    state.events = [];
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async setEvent({ commit, rootState, state }, payload) {
    const event = payload;
    event.company = rootState.settings.index.company.code;
    event.server = rootState.settings.index.system.serverName;
    event.user = localStorage.getItem("uuid");
    event.time = new Date().toISOString();
    event.src = "portal";
    commit("ADD_EVENT", event);
    if (state.events.length >= 5) {
      await this._vm.axios.post(`/api/events`, state.events);
      commit("CLEAR_EVENT");
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
