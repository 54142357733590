const initialState = () => ({
  index: {},
  indexLoading: "empty",
  complete: false
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex) {
      state[key][findIndex] = payload;
    }
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

export const actions = {
  async index({ commit }) {
    commit("SET_ENTITY", ["indexLoading", true]);
    let data = {};
    try {
      const response = await this._vm.axios.get("/api/questionnaire");
      data = response.data;
    } catch (e) {
      if (e.response.status === 404) {
        commit("SET_ENTITY", ["index", {}]);
        commit("SET_ENTITY", ["indexLoading", false]);
      }
    }
    commit("SET_ENTITY", ["index", data]);
    commit("SET_ENTITY", ["indexLoading", false]);
  },
  async showModal({ commit, rootState }) {
    if (!rootState.user.authenticate) {
      commit("user/SET_ENTITY", ["showModal", true], { root: true });
    } else {
      commit("SET_ENTITY", ["showModal", true]);
    }
  },
  async setOrder({ commit, rootState }, payload) {
    if (rootState.user.authenticate) {
      let data = {};
      try {
        const response = await this._vm.axios.post("/api/board/order", {
          ...rootState.user.user,
          orderCategory: "Услуги",
          name: payload.name
        });
        data = response.data;
        commit("ADD_ENTITY", ["index", data]);
      } catch (e) {
        if (e.response.status === 404) {
          commit("ADD_ENTITY", [
            "index",
            {
              ...rootState.user.user,
              orderCategory: "Услуги",
              name: payload.name
            }
          ]);
        }
      }
    }
  },
  async cancelOrder({ commit, rootState }, payload) {
    if (rootState.user.authenticate) {
      try {
        await this._vm.axios.put("/api/board/orders", {
          guid: rootState.order.index.find(item => item.name === payload.name)
            .guid,
          state: "canceled"
        });
        commit("DELETE_ENTITY", [
          "index",
          rootState.order.index.find(item => item.name === payload.name),
          "guid"
        ]);
      } catch (e) {
        if (e.response.status === 404) {
          commit("DELETE_ENTITY", [
            "index",
            {
              ...rootState.user.user,
              orderCategory: "Услуги",
              name: payload.name
            },
            "name"
          ]);
        }
      }
    }
  }
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
