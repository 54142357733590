<template>
  <div>
    <router-view />
    <resizer @notify="setSize" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState("flight", {
      flightDetailsLoading: state => state.flightDetailsLoading,
      flightLoading: state => state.flightLoading,
      planeLoading: state => state.planeLoading,
      serverNameLoading: state => state.serverNameLoading
    }),
    ...mapState("settings", {
      settingsIndexLoading: state => state.indexLoading,
      lastActiveDate: state => state.lastActiveDate,
      inactiveInterval: state => state.inactiveInterval
    })
  },
  mounted() {
    window.addEventListener("beforeunload", this.leaveSite);
    this.checkFlightUpdate();
    if (this.flightLoading === "empty") {
      this.actionFlightIndex();
    }
    this.actionOrderIndex();
    if (this.serverNameLoading === "empty") {
      this.getServerName();
    }
    if (this.flightDetailsLoading === "empty") {
      this.actionFlightDetailsIndex();
    }
    if (this.settingsIndexLoading === "empty") {
      this.actionSettingsIndex();
    }
    if (this.planeLoading === "empty") {
      //this.getPlane();
    }
  },
  methods: {
    ...mapActions({
      clearCache: "STATE_CLEAR"
    }),
    ...mapMutations({
      setSize: "SET_SIZE"
    }),
    leaveSite() {
      this.setSettingsEntity(["lastActiveDate", Date.now()]);
    },
    ...mapActions("flight", {
      actionFlightIndex: "flight",
      actionFlightDetailsIndex: "flightDetails",
      getPlane: "plane",
      getServerName: "serverName",
      checkFlightUpdate: "checkFlightUpdate"
    }),
    ...mapActions("settings", {
      actionSettingsIndex: "index"
    }),
    ...mapActions("order", {
      actionOrderIndex: "index"
    }),
    ...mapMutations("settings", {
      setSettingsEntity: "SET_ENTITY"
    })
  }
};
</script>
<style></style>
