const initialState = () => ({
  enabledDrm: true,
  skipHeaderDrm: false,
  preload: false,
  inactiveInterval: 60 * 60 * 24 * 1000,
  lastActiveDate: false,
  index: {},
  indexLoading: "empty"
});

export const mutations = {
  SET_ENTITY(state, [key, payload]) {
    state[key] = payload;
  },
  ADD_ENTITY(state, [key, payload, position = "start"]) {
    if (position === "end") {
      state[key].push(payload);
    } else {
      state[key].unshift(payload);
    }
  },
  UPDATE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex) {
      state[key][findIndex] = payload;
    }
  },
  DELETE_ENTITY(state, [key, payload, payloadKey]) {
    const findIndex = state[key].findIndex(item => {
      return item[payloadKey] === payload[payloadKey];
    });
    if (findIndex !== -1) {
      state[key].splice(findIndex, 1);
    }
  },
  RESET_STATE(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};
export const actions = {
  async index({ commit }) {
    commit("SET_ENTITY", ["indexLoading", true]);
    const { data } = await this._vm.axios.get("/api/settings");
    commit("SET_ENTITY", ["index", data]);
    commit("SET_ENTITY", ["indexLoading", false]);
  }
};
export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
